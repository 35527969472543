<template>
  <b-container class="p-0" fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#" v-if="vmUserRegLeadFormData && Object.keys(vmUserRegLeadFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationusrl_name">
                    {{ cvUsrlNameLabel }}</label>
                  <input v-model="vmUserRegLeadFormData.usrl_name" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationusrl_email">
                    {{ cvUsrlEmailLabel }}</label>
                  <input v-model="vmUserRegLeadFormData.usrl_email" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationusrl_type">
                    {{ cvUsrlTypeLabel }}</label>
                  <input v-model="vmUserRegLeadFormData.usrl_type" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationusrl_org_name">
                    {{ cvUsrlOrgNameLabel }}</label>
                  <input v-model="vmUserRegLeadFormData.usrl_org_name" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationusrl_contact_no">
                    {{ cvUsrlContactNoLabel }}</label>
                  <input v-model="vmUserRegLeadFormData.usrl_contact_no" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationusrl_about_myself">
                    {{ cvUsrlDescLabel }}</label>
                  <textarea v-model="vmUserRegLeadFormData.usrl_about_myself" type="text" class="form-control textarea"
                      required maxlength="150"></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationusrl_city">
                    {{ cvUsrlCityLabel }}</label>
                  <input v-model="vmUserRegLeadFormData.usrl_city" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationusrl_state">
                    {{ cvUsrlStateLabel }}</label>
                  <input v-model="vmUserRegLeadFormData.usrl_state" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationusrl_country">
                    {{ cvUsrlCountryLabel }}</label>
                  <input v-model="vmUserRegLeadFormData.usrl_country" type="text" class="form-control" required />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="userRegLeadEdit()">{{ cvSubmitBtn }}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style lang="scss" scoped>
.textarea {
  min-height: 150px;
  line-height: 27px !important;
}
.iq-card{
  box-shadow: none;
}
</style>
<script>
import { UserRegLeads } from "../../../FackApi/api/UserRegLead"
import ApiResponse from "../../../Utils/apiResponse.js"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "UserRegLeadEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propUserRegLeadObj: {
      type: Object,
      default: function () {
        return {
          "usrl_name": "",
          "usrl_email": "",
          "usrl_type": "",
          "usrl_org_name": "",
          "usrl_contact_no": "",
          "usrl_about_myself": "",
          "usrl_city": "",
          "usrl_state": "",
          "usrl_country": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "user_reg_lead_edit",
      cvCardTitle: "Contact Us Edit",
      cvCardSubHeader: "Edit userRegLead ",
      cvSubmitBtn: "Edit",
      cvUsrlNameLabel: "Name",
      cvUsrlEmailLabel: "Email",
      cvUsrlTypeLabel: "Type",
      cvUsrlOrgNameLabel: "Organisation",
      cvUsrlContactNoLabel: "Contact No",
      cvUsrlDescLabel: "Description",
      cvUsrlCityLabel: "City",
      cvUsrlStateLabel: "State",
      cvUsrlCountryLabel: "Country",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "UserRegLead",
      vmUserRegLeadFormData: {}
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.userRegLeadView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmUserRegLeadFormData) {
          if (!this.vmUserRegLeadFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * userRegLeadView
     */
    async userRegLeadView () {
      try {
        if (this.propOpenedInModal) {
          this.vmUserRegLeadFormData = this.propUserRegLeadObj
        }
        else {
          let userRegLeadId = this.$route.params.usrl_id
          let userRegLeadViewResp = await UserRegLeads.userRegLeadView(this, userRegLeadId)

          if (userRegLeadViewResp && userRegLeadViewResp.resp_status) {
            this.vmUserRegLeadFormData = userRegLeadViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at userRegLeadView() and Exception:", err.message)
      }
    },
    /**
     * userRegLeadEdit
     */
    async userRegLeadEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let userRegLeadAddResp = await UserRegLeads.userRegLeadEdit(this, this.vmUserRegLeadFormData)
        await ApiResponse.responseMessageDisplay(this, userRegLeadAddResp)

        if (userRegLeadAddResp && !userRegLeadAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseUserRegLeadEditModal", this.vmUserRegLeadFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at userRegLeadEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
